<template>
    <div class="menu-editor">
        <div v-if="!isLoad">Loading...</div>
        <div v-else-if="loadError" class="error">{{ loadError }}</div>
        <template v-else>
            <MenuEditorList
                v-model="list"
                class="menu-editor__list"
                :level="1"
                :depth="depth"
                @add-child-for-menu-item="addChildForMenuItem"
                @edit-menu-item="editMenuItem"
                @remove-menu-item="removeMenuItem"
                @end-changed="dragEndHandler"
            />
            <Button class="menu-editor__btn-add" icon="add_circle_outline" :fullwidth="true" @click="addMenuItem">
                Add menu item
            </Button>
        </template>
    </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import { debounce, cloneDeep } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import MenuService from '@/services/MenuService';
import MenuEditorList from '@/components/builder/menuEditor/list.vue';
import Button from '@/components/common/Button.vue';

export default {
    name: 'MenuEditor',
    components: { MenuEditorList, Button },
    props: {
        value: { type: String, default: 'main' },
        label: { type: String },
        depth: { type: Number, default: 1 },
    },
    data() {
        return {
            isLoad: false,
            loadError: false,
            list: [],
        };
    },
    async created() {
        await this.getMenu();
    },
    methods: {
        async getMenu() {
            const [error, menu] = await MenuService.getOne(this.value);
            if (error) {
                error.alert();
                this.loadError = error.message;
                return;
            }
            this.isLoad = true;
            this.list = this.convertMenuForEditor(menu);
        },
        convertMenuForEditor(menu, parentId = null) {
            return menu
                .filter((item) => item.parentId === parentId)
                .map((item) => {
                    item.elements = this.convertMenuForEditor(menu, item.id);
                    if (item.uri) item.subject.name = 'link';
                    return item;
                });
        },
        convertMenuForAPI(list) {
            let menu = [];
            const cloneList = cloneDeep(list);
            cloneList.forEach((element) => {
                if (element.subject.name === 'link') element.subject.name = null;
                menu.push(element);
                if (element.elements.length) {
                    const subElements = this.convertMenuForAPI(element.elements);
                    menu = menu.concat(subElements);
                }
            });
            menu.forEach((element) => {
                delete element.elements;
            });
            return menu;
        },
        async addMenuItem() {
            EventEmitter.trigger('progress-bar__in-progress');
            const newItem = {
                id: uuid.v4(),
                parentId: null,
                title: 'New Item',
                subject: { name: null, id: null, useJumpLink: false, sectionId: null },
                uri: null,
                position: this.list.length,
            };
            const [error, result] = await MenuService.createItem(this.value, newItem);
            if (error) error.alert();
            else {
                result.elements = [];
                this.list.push(result);
            }
            EventEmitter.trigger('progress-bar__done');
            EventEmitter.presets.public.updateMenu(this.value);
        },
        async addChildForMenuItem(id) {
            EventEmitter.trigger('progress-bar__in-progress');
            const item = this.findItemById(this.list, id);
            console.log({
                list: this.list,
                item,
            });
            const newItem = {
                id: uuid.v4(),
                parentId: item.id,
                title: 'New Item',
                subject: { name: null, id: null, useJumpLink: false, sectionId: null },
                uri: null,
                position: Array.isArray(item.elements) ? item.elements.length : 0,
            };
            const [error, result] = await MenuService.createItem(this.value, newItem);
            if (error) error.alert();
            else {
                result.elements = [];
                item.elements.push(result);
            }
            EventEmitter.trigger('progress-bar__done');
            EventEmitter.presets.public.updateMenu(this.value);
        },
        async editMenuItem(item) {
            EventEmitter.trigger('progress-bar__in-progress');
            const updateItem = cloneDeep(item);
            delete updateItem.elements;
            if (updateItem.subject.name === 'link') updateItem.subject.name = null;
            const [error] = await MenuService.updateItem(this.value, updateItem);
            if (error) error.alert();
            EventEmitter.trigger('progress-bar__done');
            EventEmitter.presets.public.updateMenu(this.value);
        },
        async removeMenuItem(id) {
            EventEmitter.trigger('progress-bar__in-progress');
            const item = this.findItemById(this.list, id);
            if (!item) return;
            const [error] = await MenuService.removeItem(this.value, item.id);
            if (error) error.alert();
            else {
                if (item.parentId) {
                    const parent = this.findItemById(this.list, item.parentId);
                    const itemIndex = parent.elements.indexOf(item);
                    if (itemIndex > -1) parent.elements.splice(itemIndex, 1);
                } else {
                    const itemIndex = this.list.indexOf(item);
                    if (itemIndex > -1) this.list.splice(itemIndex, 1);
                }
            }
            EventEmitter.trigger('progress-bar__done');
            EventEmitter.presets.public.updateMenu(this.value);
        },
        findItemById(list, id) {
            let item = null;
            item = list.find((element) => element.id === id);
            if (item) return item;
            for (let element of list) {
                if (element.elements.length) item = this.findItemById(element.elements, id);
                if (item) return item;
            }
            return item;
        },
        updateMenuPositionAndParants(list, id) {
            list.forEach((element, index) => {
                element.parentId = id;
                element.position = index;
                if (element.elements.length) this.updateMenuPositionAndParants(element.elements, element.id);
            });
        },
        dragEndHandler: debounce(async function() {
            EventEmitter.trigger('progress-bar__in-progress');
            this.updateMenuPositionAndParants(this.list, null);
            const menu = this.convertMenuForAPI(this.list);
            const [error] = await MenuService.updateBatch(this.value, menu);
            if (error) error.alert();
            EventEmitter.trigger('progress-bar__done');
            EventEmitter.presets.public.updateMenu(this.value);
        }, 100),
    },
};
</script>

<style lang="scss">
.menu-editor {
    position: relative;
    z-index: 1;
    &__list {
        position: relative;
        z-index: 2;
        margin-bottom: 1rem;
    }

    &__btn-add {
        display: flex;
    }
}
</style>
