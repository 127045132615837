export default {
    scheme: 'scheme',
    common: {
        content_width: 'content_width',
        border: {
            radius_block: 'border_radius_block',
            radius_btn: 'border_radius_btn',
            radius_input: 'border_radius_input',
        },
    },
    fonts: {
        headings: {
            font_family: 'headings_font_family',
            font_style: 'headings_font_style',
            font_weight: 'headings_font_weight',
            h1_font_size: {
                desktop: 'headings_h1_font_size_desktop',
                tablet: 'headings_h1_font_size_tablet',
                mobile: 'headings_h1_font_size_mobile',
            },
            h2_font_size: {
                desktop: 'headings_h2_font_size_desktop',
                tablet: 'headings_h2_font_size_tablet',
                mobile: 'headings_h2_font_size_mobile',
            },
            h3_font_size: {
                desktop: 'headings_h3_font_size_desktop',
                tablet: 'headings_h3_font_size_tablet',
                mobile: 'headings_h2_font_size_mobile',
            },
            h4_font_size: {
                desktop: 'headings_h4_font_size_desktop',
                tablet: 'headings_h4_font_size_tablet',
                mobile: 'headings_h2_font_size_mobile',
            },
        },
        body: {
            font_family: 'body_font_family',
            font_size_desktop: 'body_font_size_desktop',
            font_size_tablet: 'body_font_size_tablet',
            font_size_mobile: 'body_font_size_mobile',
        },
    },
    generals: {
        main: {
            background: 'main_background',
            headline_color: 'main_headline_color',
            content_text: 'main_content_text',
            content_link: 'main_content_link',
            content_link_hover: 'main_content_link_hover',
            content_link_active: 'main_content_link_active',
        },
        inverted: {
            background: 'inverted_background',
            headline_color: 'inverted_headline_color',
            content_text: 'inverted_content_text',
            content_link: 'inverted_content_link',
            content_link_hover: 'inverted_content_link_hover',
            content_link_active: 'inverted_content_link_active',
        },
        middle: {
            background: 'middle_background',
            headline_color: 'middle_headline_color',
            content_text: 'middle_content_text',
            content_link: 'middle_content_link',
            content_link_hover: 'middle_content_link_hover',
            content_link_active: 'middle_content_link_active',
        },
    },
    buttons: {
        button_primary: {
            background: 'button_primary_background',
            background_hover: 'button_primary_background_hover',
            background_active: 'button_primary_background_active',
            background_disabled: 'button_primary_background_disabled',
            text: 'button_primary_text',
            text_hover: 'button_primary_text_hover',
            text_active: 'button_primary_text_active',
        },
        button_secondary: {
            background: 'button_secondary_background',
            background_hover: 'button_secondary_background_hover',
            background_active: 'button_secondary_background_active',
            background_disabled: 'button_secondary_background_disabled',
            text: 'button_secondary_text',
            text_hover: 'button_secondary_text_hover',
            text_active: 'button_secondary_text_active',
        },
        button_outline: {
            background: 'button_outline_background',
            background_hover: 'button_outline_background_hover',
            background_active: 'button_outline_background_active',
            background_disabled: 'button_outline_background_disabled',
            text: 'button_outline_text',
            text_hover: 'button_outline_text_hover',
            text_active: 'button_outline_text_active',
        },
    },
    header: {
        mobile: {
            separator: 'header_mobile_separator',
            contacts_text: 'header_mobile_contacts_text',
            info: {
                background: 'header_mobile_info_background',
                text: 'header_mobile_info_text',
                icon: 'header_mobile_info_icon',
            },
            main: {
                background: 'header_mobile_main_background',
                icon: 'header_mobile_main_icon',
            },
            bottom: {
                background: 'header_mobile_bottom_background',
                text_links: 'header_mobile_bottom_text_links',
                text_links_background: 'header_mobile_bottom_text_links_background',
                icon: 'header_mobile_bottom_icon',
            },
            menu: {
                background: 'header_mobile_menu_background',
                lines: 'header_mobile_menu_lines',
                icons: 'header_mobile_menu_icons',
                text_links: 'header_mobile_menu_text_links',
                text_links_active: 'header_mobile_menu_text_links_active',
            },
        },
        desktop: {
            separator_background: 'header_desktop_separator_background',
            top: {
                background: 'header_desktop_top_background',
                text_link: 'header_desktop_top_text_link',
                text_link_hover: 'header_desktop_top_text_link_hover',
                icon: 'header_desktop_top_icon',
                icon_hover: 'header_desktop_top_icon_hover',
            },
            main: {
                background: 'header_desktop_main_background',
                text_link: 'header_desktop_main_text_link',
                text_link_hover: 'header_desktop_main_text_link_hover',
                icon: 'header_desktop_main_icon',
                icon_hover: 'header_desktop_main_icon_hover',
            },
            bottom: {
                background: 'header_desktop_bottom_background',
                text_link: 'header_desktop_bottom_text_link',
                text_link_hover: 'header_desktop_bottom_text_link_hover',
                icon: 'header_desktop_bottom_icon',
                icon_hover: 'header_desktop_bottom_icon_hover',
            },
            search: {
                background: 'header_desktop_search_background',
                border: 'header_desktop_search_border',
                text: 'header_desktop_search_text',
                icon: 'header_desktop_search_icon',
                background_hover: 'header_desktop_search_background_hover',
                border_hover: 'header_desktop_search_border_hover',
                text_hover: 'header_desktop_search_text_hover',
                icon_hover: 'header_desktop_search_icon_hover',
            },
            menu: {
                text_link: 'header_desktop_menu_text_link',
                text_link_hover: 'header_desktop_menu_text_link_hover',
                text_link_active: 'header_desktop_menu_text_link_active',
                submenu_background: 'header_desktop_submenu_background',
                submenu_text_link: 'header_desktop_submenu_text_link',
                submenu_text_link_hover: 'header_desktop_submenu_text_link_hover',
                submenu_text_link_active: 'header_desktop_submenu_text_link_active',
            },
            menu_info: {
                text_link: 'header_desktop_menu_info_text_link',
                text_link_hover: 'header_desktop_menu_info_text_link_hover',
                text_link_active: 'header_desktop_menu_info_text_link_active',
            },
        },
    },
    footer: {
        main_background: 'footer_main_background',
        bottom_background: 'footer_bottom_background',
        lines_background: 'footer_lines_background',
        text: 'footer_text',
        link: 'footer_link',
        link_hover: 'footer_link_hover',
        link_active: 'footer_link_active',
        bottom_text: 'footer_bottom_text',
        social_icon: 'footer_social_icon',
        social_icon_hover: 'footer_social_icon_hover',
        social_icon_active: 'footer_social_icon_active',
        icon: {
            color: 'footer_icon_color',
            background: 'footer_icon_background',
        },
        menu: {
            text_link: 'footer_menu_text_link',
            text_link_hover: 'footer_menu_text_link_hover',
            text_link_active: 'footer_menu_text_link_active',
        },
        submenu: {
            text_link: 'footer_submenu_text_link',
            text_link_hover: 'footer_submenu_text_link_hover',
            text_link_active: 'footer_submenu_text_link_active',
        },
    },
    form: {
        input: {
            background: 'form_input_background',
            border: 'form_input_border',
            border_hover: 'form_input_border_hover',
            text: 'form_input_text',
            text_placeholder: 'form_input_text_placeholder',
        },
        select: {
            background: 'form_select_background',
            border: 'form_select_border',
            text: 'form_select_text',
            icon: 'form_select_icon',
            option_text: 'form_select_option_text',
            option_text_hover: 'form_select_option_text_hover',
            option_background_hover: 'form_select_option_background_hover',
        },
    },
    system: {
        success: 'success',
        success_bg: 'success_bg',
        warning: 'warning',
        warning_bg: 'warning_bg',
        error: 'error',
        error_bg: 'error_bg',
    },
    accent: {
        accent1: 'accent1',
        accent1_bg: 'accent1_bg',
        accent2: 'accent2',
        accent2_bg: 'accent2_bg',
        accent3: 'accent3',
        accent3_bg: 'accent3_bg',
        accent4: 'accent4',
        accent4_bg: 'accent4_bg',
    },
    product_list: {
        background: 'product_list_background',
        title: 'product_list_title',
        description: 'product_list_description',
        price: 'product_list_price',
        price_discount: 'product_list_price_discount',
        reviews_start: 'product_list_reviews_start',
        reviews_text: 'product_list_reviews_text',
        availability: 'product_list_availability',
        button_buy: {
            background: 'product_list_button_buy_background',
            background_hover: 'product_list_button_buy_background_hover',
            background_active: 'product_list_button_buy_background_active',
            background_disabled: 'product_list_button_buy_background_disabled',
            text: 'product_list_button_buy_text',
            text_hover: 'product_list_button_buy_text_hover',
            text_active: 'product_list_button_buy_text_active',
        },
        counter: {
            background: 'product_list_counter_background',
            color: 'product_list_counter_color',
            btn_background: 'product_list_counter_btn_background',
            btn_color: 'product_list_counter_btn_color',
            btn_background_hover: 'product_list_counter_btn_background_hover',
            btn_color_hover: 'product_list_counter_btn_color_hover',
            btn_background_active: 'product_list_counter_btn_background_active',
            btn_color_active: 'product_list_counter_btn_color_active',
        },
        parameters: {
            background: 'product_list_parameters_background',
            icon_color: 'product_list_parameters_icon_color',
            icon_background: 'product_list_parameters_icon_background',
            text_color: 'product_list_parameters_text_color',
            text_border: 'product_list_parameters_text_border',
        },
        options_button: {
            background: 'product_list_options_button_background',
            border: 'product_list_options_button_border',
            text: 'product_list_options_button_text',
            background_hover: 'product_list_options_button_background_hover',
            border_hover: 'product_list_options_button_border_hover',
            text_hover: 'product_list_options_button_text_hover',
            background_selected: 'product_list_options_button_background_selected',
            border_selected: 'product_list_options_button_border_selected',
            text_selected: 'product_list_options_button_text_selected',
        },
        options_select: {
            background: 'product_list_options_select_background',
            border: 'product_list_options_select_border',
            text: 'product_list_options_select_text',
            icon: 'product_list_options_select_icon',
            option_text: 'product_list_options_select_option_text',
            option_text_hover: 'product_list_options_select_option_text_hover',
            option_background_hover: 'product_list_options_select_option_background_hover',
        },
    },
    filter: {
        mobile: {
            background: 'filter_mobile_background',
            border: 'filter_mobile_border',
            text: 'filter_mobile_text',
            icon: 'filter_mobile_icon',
        },
        desktop: {
            background: 'filter_desktop_background',
            border: 'filter_desktop_border',
            text: 'filter_desktop_text',
            icon: 'filter_desktop_icon',
        },
        button: {
            background: 'filter_button_background',
            background_hover: 'filter_button_background_hover',
            background_active: 'filter_button_background_active',
            background_disabled: 'filter_button_background_disabled',
            text: 'filter_button_text',
            text_hover: 'filter_button_text_hover',
            text_active: 'filter_button_text_active',
        },
    },
    paging: {
        background: 'paging_background',
        background_hover: 'paging_background_hover',
        background_active: 'paging_background_active',
        background_disabled: 'paging_background_disabled',
        text: 'paging_text',
        text_hover: 'paging_text_hover',
        text_active: 'paging_text_active',
        text_disabled: 'paging_text_disabled',
    },
    product_card: {
        background: 'product_card_background',
        title: 'product_card_title',
        description: 'product_card_description',
        price: 'product_card_price',
        price_discount: 'product_card_price_discount',
        reviews_start: 'product_card_reviews_start',
        reviews_text: 'product_card_reviews_text',
        availability: 'product_card_availability',
        button_buy: {
            background: 'product_card_button_buy_background',
            background_hover: 'product_card_button_buy_background_hover',
            background_active: 'product_card_button_buy_background_active',
            background_disabled: 'product_card_button_buy_background_disabled',
            text: 'product_card_button_buy_text',
            text_hover: 'product_card_button_buy_text_hover',
            text_active: 'product_card_button_buy_text_active',
        },
        counter: {
            background: 'product_card_counter_background',
            color: 'product_card_counter_color',
            btn_background: 'product_card_counter_btn_background',
            btn_color: 'product_card_counter_btn_color',
            btn_background_hover: 'product_card_counter_btn_background_hover',
            btn_color_hover: 'product_card_counter_btn_color_hover',
            btn_background_active: 'product_card_counter_btn_background_active',
            btn_color_active: 'product_card_counter_btn_color_active',
        },
        parameters: {
            background: 'product_card_parameters_background',
            icon_color: 'product_card_parameters_icon_color',
            icon_background: 'product_card_parameters_icon_background',
            text_color: 'product_card_parameters_text_color',
            text_border: 'product_card_parameters_text_border',
        },
        options_button: {
            background: 'product_card_options_button_background',
            border: 'product_card_options_button_border',
            text: 'product_card_options_button_text',
            background_hover: 'product_card_options_button_background_hover',
            border_hover: 'product_card_options_button_border_hover',
            text_hover: 'product_card_options_button_text_hover',
            background_selected: 'product_card_options_button_background_selected',
            border_selected: 'product_card_options_button_border_selected',
            text_selected: 'product_card_options_button_text_selected',
        },
        options_select: {
            background: 'product_card_options_select_background',
            border: 'product_card_options_select_border',
            text: 'product_card_options_select_text',
            icon: 'product_card_options_select_icon',
            option_text: 'product_card_options_select_option_text',
            option_text_hover: 'product_card_options_select_option_text_hover',
            option_background_hover: 'product_card_options_select_option_background_hover',
        },
    },
    cart: {
        cart_border: 'cart_border',
    },
    depricated: {
        neutral1: 'neutral1',
        neutral4: 'neutral4',
        neutral5: 'neutral5',
    },
};
