<template>
    <div class="settings-item-dropdown">
        <button class="settings-item-dropdown__header" @click="toggleItem">
            <span class="settings-item-dropdown__header-title"> {{ title }}</span>
            <span class="material-icons-outlined settings-item-dropdown__header-more" :class="classes"
                >expand_more</span
            >
        </button>
        <div class="settings-item-dropdown__content" :class="classes">
            <div class="settings-item-dropdown__content-wrapper"><slot></slot></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'settings-item-dropdown',
    props: {
        title: { type: String, default: '' },
        name: { type: String, default: '' },
        exclusive: { type: Boolean, default: false },
        value: { type: String, default: '' },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        classes() {
            if (this.exclusive) {
                return { 'is-open': this.value && this.value === this.name };
            }
            return { 'is-open': this.isOpen };
        },
    },
    methods: {
        toggleItem() {
            if (!this.exclusive) {
                this.isOpen = !this.isOpen;
                return;
            }
            if (!this.name) {
                throw new Error('With this.exclusive as "true" this.name is required');
            }
            this.$emit('input', this.value === this.name ? null : this.name);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
@import '@/scss/variables/system-colors.scss';
.settings-item-dropdown {
    &.highlight {
        background: lighten($item-hover, 2%);
        z-index: 1;
        &__header {
            background: darken($item-hover, 10%);
        }
    }
    &.highlight &__header {
        background: $item-active;
    }
    &__header {
        display: flex;
        align-items: center;
        width: calc(100% + 32px);
        padding: 0 16px;
        margin-left: -16px;
        min-height: 56px;
        background: none;
        cursor: pointer;
        position: relative;
        transition: $transition-fast;
        border: none;

        &:hover {
            background: #edf5fc;
            .settings-item-dropdown__header-more,
            .settings-item-dropdown__header-title {
                transition: $transition-fast;

                color: var(--v-primary-base);
            }
        }

        &-title {
            font-size: 1.1429rem;
            color: #2c2d2e;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: auto;
        }

        &-more {
            cursor: pointer;
            color: inherit;
            &.is-open {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        display: block;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: $transition-default;

        &.is-open {
            max-height: 7000px;
            opacity: 1;
            pointer-events: auto;
        }

        &-wrapper {
            padding: 16px;
        }
    }

    .option-list-settings__item-field {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.highlight {
        & > .settings-item-dropdown__header {
            .settings-item-dropdown__header-title,
            .settings-item-dropdown__header-more {
                color: var(--v-primary-base) !important;
            }
        }
    }
    .settings-item-dropdown__content-wrapper {
        padding: 0;
    }
    .option-list-settings__item-field {
        &:last-child {
            padding-bottom: 16px;
        }
        &:first-child {
            padding-top: 16px;
        }
    }
}
</style>
