<template>
    <div class="template-builder" v-if="!this.isLoading">
        <SelectTemplate
            :items="summary.itemsForSelect"
            :value="selected"
            :name="name"
            :width="sidebarWidth"
            @input="changeTemplate"
        ></SelectTemplate>
        <div v-if="selected" class="template-builder__customize-checkbox" @click="isCustom = !isCustom">
            <span class="material-icons-outlined">{{ isCustom ? 'remove' : 'add' }}</span> Customize
        </div>
        <template v-if="isCustom">
            <div class="template-builder__form">
                <div class="template-builder__button-toggles">
                    <ButtonToggles
                        v-model="value.onScroll"
                        :items="[
                            { text: 'Static', value: 'static' },
                            { text: 'Sticky', value: 'sticky' },
                        ]"
                    ></ButtonToggles>
                </div>
                <SettingsItemDropdown
                    exclusive
                    v-for="(level, index) in value.template"
                    :name="level.type"
                    v-model="currentActiveDropdown"
                    :title="$t(`settings.header.templateLevels.${level.type.toLowerCase()}`)"
                    :key="index"
                >
                    <template v-for="(item, itemIndex) in level.items">
                        <template-builder-input-group
                            @input="handleGroupInput($event, index, itemIndex)"
                            :key="itemIndex"
                            :value="item"
                            :type="level.type"
                            :has-separator="itemIndex < level.items.length - 1"
                            :default-props="summary.constructor.defaultProps"
                        ></template-builder-input-group>
                        <!--
                        <div :key="key" v-else-if="item.separator" class="template-builder__separator"></div>
-->
                    </template>
                </SettingsItemDropdown>
            </div>
            <div class="template-builder__settings-link" @click="goToHeaderStyles">
                <span class="material-icons-outlined">arrow_back</span> Go to header color settings
            </div>
        </template>
    </div>
</template>

<script>
import SelectTemplate from '@/components/builder/optionList/template-builder/select-template';
import SettingsItemDropdown from '@/components/builder/optionList/settings-item-dropdown';
import icons from '@/entities/icons';
import ButtonToggles from '@/components/form/controls/ButtonToggles';
import EventEmitter from '@/helpers/eventEmitter';
import TemplateBuilderInputGroup from '@/components/builder/optionList/template-builder/template-builder-input-group';
import { cloneDeep } from 'lodash';
export default {
    name: 'template-builder',
    components: {
        TemplateBuilderInputGroup,
        SettingsItemDropdown,
        SelectTemplate,
        ButtonToggles,
    },
    fixtures: {
        headerMobile: async () => await import('@/entities/section/fixtures/headerMobile/summary'),
        headerDesktop: async () => await import('@/entities/section/fixtures/headerDesktop/summary'),
    },

    async created() {
        this.isLoading = true;
        const module = await this.$options.fixtures[this.entity]();
        if (module) {
            this.summary = module.default;
        }
        this.isLoading = false;
    },
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date, Boolean] },
        sidebarWidth: {
            type: Number,
            default: 340,
        },
        entity: { type: String, default: '' },
    },
    data() {
        return {
            templates: [],
            selectValue: null,
            defaultProps: null,
            currentActiveDropdown: null,
            summary: null,
            isLoading: false,
            buttonTypes: [
                { text: 'Link', value: 'link' },
                { text: 'Popup', value: 'popup' },
                { text: 'Phone', value: 'phone' },
                { text: 'Whatsapp', value: 'whatsapp' },
            ],
            isCustom: false,
            icons,
        };
    },
    computed: {
        selected() {
            return this.value.title;
        },
    },
    methods: {
        changeTemplate(val) {
            const newTemplate = this.summary.items[val];
            this.$emit('input', newTemplate.data);
        },
        handleGroupInput(val, levelIndex, index) {
            const newValue = cloneDeep(this.value);
            newValue.template[levelIndex].items[index] = val;
            this.$emit('input', newValue);
        },
        goToHeaderStyles() {
            EventEmitter.trigger('settings-open-group', this.summary.constructor.stylesPath);
        },
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.fields);
            }
            return fieldEvents;
        },
    },
    watch: {
        value: {
            handler(newValue) {
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.form-item.option-list-settings {
    &__template-builder {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 16px;
        transition: $transition-fast;
        .settings-item-dropdown {
            width: calc(100% + 32px);
            margin-left: -16px;
            padding: 0 16px;
        }
        &:hover {
            background-color: var(--v-primary-lighten-base) !important;
            .settings-item-dropdown {
                &__header {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
.template-builder {
    &__form {
        padding: 16px 0;
    }
    &__form-sub-item {
        margin-top: 16px !important;
    }
    .settings-item-dropdown {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    &__button-toggles {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .select-template {
        margin-bottom: 16px;
    }
    &__customize-checkbox {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        color: var(--v-on-surface-medium-base);
        font-weight: 500;
        transition: $transition-fast;
        .material-icons-outlined {
            font-size: 18px;
        }
        gap: 8px;
        &:hover {
            color: var(--v-primary-base);
            cursor: pointer;
        }
    }
    &__settings-link {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        color: var(--v-primary-base);
        font-weight: 500;
        transition: $transition-fast;
        .material-icons-outlined {
            font-size: 18px;
            transform: rotate(135deg);
        }
        gap: 8px;
        &:hover {
            color: var(--v-primary-accent-base);
            cursor: pointer;
        }
    }
}
</style>
