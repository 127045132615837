import { Quill } from 'vue2-editor';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

export default class QuillClearPaste extends Clipboard {
    onPaste(e) {
        e.preventDefault();
        const range = this.quill.getSelection();
        let text = e.clipboardData.getData('text/plain');
        // const reg = new RegExp(/[^+\d]/g);
        // text = text.replace(reg, '');
        text = text.replaceAll(/\n\n|\r\n\r\n/g, '\n');
        const delta = new Delta()
            .retain(range.index)
            .delete(range.length)
            .insert(text);
        const index = text.length + range.index;
        const length = 0;
        this.quill.updateContents(delta, 'user');
        this.quill.setSelection(index, length, 'user');
        this.quill.scrollIntoView();
    }
}
