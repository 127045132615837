<template>
    <div class="code-editor">
        <vue-editor
            v-model="model"
            :editorToolbar="$options.customToolbar"
            :editorOptions="$options.editorOptions"
        ></vue-editor>
    </div>
</template>

<script>
// TODO: в папке UiComponents остался дубль
// пригодиться если нужно будет добавить несколько пользвательских
import { VueEditor, Quill } from 'vue2-editor';
// import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import QuillClearPaste from '@/quill/quill-clear-paste.js';

Quill.register('modules/clipboard', QuillClearPaste, true);

const Parchment = Quill.import('parchment');
const Delta = Quill.import('delta');

class ShiftEnterBlot extends Parchment.Embed {} // Actually EmbedBlot
ShiftEnterBlot.blotName = 'ShiftEnter';
ShiftEnterBlot.tagName = 'br';

Quill.register(ShiftEnterBlot);

export default {
    name: 'CodeEditor',

    inheritAttrs: false,
    editorOptions: {
        modules: {
            clipboard: {
                matchVisual: false,
            },
            keyboard: {
                bindings: [
                    {
                        key: 'Enter',
                        shiftKey: true,
                        handler: function(range) {
                            this.quill.updateContents(
                                new Delta()
                                    .retain(range.index)
                                    .delete(range.length)
                                    .insert({ ShiftEnter: true }),
                                'user'
                            );

                            if (!this.quill.getLeaf(range.index + 1)[0].next) {
                                this.quill.updateContents(
                                    new Delta()
                                        .retain(range.index + 1)
                                        .delete(0)
                                        .insert({ ShiftEnter: true }),
                                    'user'
                                );
                            }

                            this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                            return false; // Don't call other candidate handlers
                        },
                    },
                ],
            },
        },
    },
    customToolbar: [
        [{ header: [2, 3, false] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['bold', 'italic', 'underline'],
        ['clean'],
    ],

    // fullToolbar: [
    //     [{ font: [] }],
    //     [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    //     [{ size: ['small', false, 'large', 'huge'] }],
    //     ['bold', 'italic', 'underline', 'strike'],
    //     [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    //     [{ header: 1 }, { header: 2 }],
    //     ['blockquote', 'code-block'],
    //     [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    //     [{ script: 'sub' }, { script: 'super' }],
    //     [{ indent: '-1' }, { indent: '+1' }],
    //     [{ color: [] }, { background: [] }],
    //     ['link', 'image', 'video', 'formula'],
    //     [{ direction: 'rtl' }],
    //     ['clean'],
    // ],

    components: { VueEditor },
    props: {
        value: { type: [String, Number, Object] },
        name: { type: String },
        group: { type: String },
        errors: { type: [Array, Object], default: () => [] },
    },

    computed: {
        model: {
            get() {
                const newValue = this.value?.replace(/[\u200B-\u200D\uFEFF]/g, '');
                return newValue;
            },
            set(value) {
                const regex = new RegExp('\u2028|\u2029');
                const newValue = value.replace(regex, '');
                this.$emit('input', newValue);
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.code-editor {
    .quillWrapper {
        .ql-toolbar.ql-snow {
            $margin-right: 26px;

            padding: 0;
            border: none;
            margin-right: -$margin-right;

            & + .ql-container.ql-snow {
                border-top: 1px solid var(--v-outline-base);
            }

            .ql-formats {
                border: 1px solid var(--v-outline-base);
                //margin: 0 $margin-right $spacer * 2 0;
                margin: 0 6px $spacer * 2 0;
                border-radius: $spacer;

                /*  &:first-of-type {
                    width: calc(100% - #{$margin-right});
                    border: none;
                }*/

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 34px;
                    padding: 0;
                    border-right: 1px solid var(--v-outline-base);

                    &:last-child {
                        border-right: none;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .ql-picker {
            &.ql-header {
                top: 0;
                // width: 100%;
                height: 36px;

                svg {
                    display: none;
                }
            }
            .ql-picker-label {
                display: flex;
                align-self: center;
                padding: 0 $spacer * 4;
                //border: 1px solid var(--v-outline-base);
                border-radius: $spacer;
                background: #fff;

                &::before {
                    display: flex;
                    align-items: center;
                    font-size: 1rem;
                    color: var(--v-on-surface-high-base);
                    font-weight: 400;
                }

                &::after {
                    content: 'arrow_drop_down';
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    display: block;
                    width: 24px;
                    height: 24px;
                    font-size: 24px;
                    line-height: 24px;
                    margin-top: -12px;
                    font-family: 'Material Icons';
                    color: var(--v-on-surface-high-base);
                }
            }

            .ql-picker-options {
                border: 1px solid var(--v-outline-base);
                border-radius: $spacer;
                padding: 0;
                overflow: hidden;

                .ql-picker-item {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    padding: 0 $spacer * 4;

                    &::before {
                        font-size: 1rem !important;
                        color: var(--v-on-surface-high-base);
                        font-weight: 400;
                    }

                    &:hover {
                        background: var(--v-surface-overlay-base);
                    }
                }
            }

            &.ql-expanded {
                .ql-picker-label {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .ql-picker-options {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }

        .ql-container.ql-snow {
            border: 1px solid var(--v-outline-base);
            border-radius: $spacer;
            background: #fff;
        }

        .ql-formats {
            background: #fff;
        }

        .ql-editor {
            min-height: 120px;
        }

        .ql-tooltip {
            left: 0 !important;
            max-width: 100%;
            white-space: unset;
        }
    }
}
.code-editor .ql-editor {
    // $font-size-base: 14px; /* базовый размер шрифта */
    // $font-weight-base: 400;
    // $line-height-base: 1.5; /* базовый междустрочный интервал */
    $text-margin: 20px;

    p,
    ul,
    ol,
    table,
    pre {
        margin: 0 0 $text-margin;
    }

    ul,
    ol {
        padding-left: 22px;

        li {
            margin: 8px 0;
        }
    }
}
</style>
