<template>
    <div class="input-ratio" :class="{ '-has-errors': errors.length }">
        <template v-for="(value, index) in inputValues">
            <input-text
                ref="input"
                :key="index"
                @keydown.native="handleDelete(index, $event)"
                @keydown.native.enter="focusOnInput(index, $event)"
                :value="value"
                @focusout.native="handleBlur($event, index)"
                @input.native="handleNumbers($event, index)"
                class="form-item__field input-ratio__item"
            />
            <span v-if="index + 1 < inputValues.length" :key="`${index}-separator`">{{ separator }}</span>
        </template>
    </div>
</template>

<script>
import { isEqual } from 'lodash';
import InputText from '@/components/form/controls/InputText';
export default {
    //  directives: { clickOutside },
    name: 'InputRatio',
    components: { InputText },
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: Object,
            default: () => {},
        },
        name: { type: String, default: '' },
        group: { type: String, default: null },
        errors: { type: Array, default: () => [] },
        separator: { type: String, default: 'x' },
        min: { type: Number },
        max: { type: Number },
    },
    data() {
        return {
            inputValues: [],
        };
    },
    created() {
        this.inputValues = this.value.split(this.separator);
    },
    methods: {
        handleBlur(e) {
            const hasActiveInput = this.$refs.input.some((item) => {
                const input = item.$el.querySelector('input');
                return e.relatedTarget === input;
            });
            if (!hasActiveInput) {
                this.$refs.input.forEach((item, index) => {
                    const regEx = /\d+/;
                    const input = item.$el.querySelector('input');
                    let number = input.value.match(regEx)?.[0];
                    if (this.min && (!number || number < this.min)) {
                        number = this.min;
                        this.inputValues[index] = number;
                        input.value = number;
                    }
                });

                const value = this.inputValues.join(this.separator);
                this.$emit('input', value);
            }
        },
        handleDelete(index, e) {
            //обрабатываем backspace на пустом инпуте
            const keyPressed = e.key;
            if (keyPressed === 'Backspace' || keyPressed === 'Delete') {
                const prevInput = this.$refs.input[index - 1]?.$el?.querySelector('input');
                //если вводом ., то возвращается компонентом text-input пустое значение
                const currentInputValue = this.$refs.input[index].$el.querySelector('input').value;
                if (!currentInputValue && prevInput) {
                    prevInput.focus();
                }
            }
        },
        handleNumbers(e, index) {
            const regEx = /\d+/;
            let number = e.target.value.match(regEx)?.[0];
            if (this.max && number > this.max) {
                number = this.max;
            }
            this.inputValues[index] = number;
            e.target.value = number ? number : '';
        },
        focusOnInput(index, event) {
            const nextInput = this.$refs.input[index + 1];
            if (nextInput) {
                nextInput.$el.querySelector('input').focus();
            } else {
                event.target.blur();
            }
        },
    },
    watch: {
        value: function(newValue, oldValue) {
            if (!isEqual(newValue, oldValue)) {
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-ratio {
    &.-has-errors {
        .input-text {
            border-color: #e64a19;
        }
    }
    &.form-item__field {
        &:active {
            caret-color: transparent !important;
        }
    }

    display: flex;
    gap: 8px;
    align-items: center;
    &__item {
        // width: 80px;
        input {
            //   text-align: center;
        }
    }
    &.-focused {
    }
}
</style>
