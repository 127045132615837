export default function(varsObj) {
    // const newCommon = {
    //     scheme: varsObj.scheme,
    //     content_width: varsObj.content_width,
    //     border_radius_block: varsObj.border_radius_block || '16',
    //     border_radius_btn: varsObj.border_radius_btn || '8',
    //     border_radius_input: varsObj.border_radius_input || '8',
    // };
    // const newFonts = {
    //     font_family_head: varsObj.font_family_head,
    //     font_family_body: varsObj.font_family_body,
    //     font_style_head: varsObj.font_style_head,
    //     font_style_body: varsObj.font_style_body,
    //     // размеры и стили для h1 2 3 4
    // };
    // GENERALS ===================================================================
    const newGenerals = {
        // main -----------------------------------------------------------------------
        main_background: varsObj.neutral7,
        main_headline_color: varsObj.text_main,
        main_content_text: varsObj.text_body1,
        main_content_link: varsObj.action_secondary,
        main_content_link_hover: varsObj.action_secondary_hover,
        main_content_link_active: varsObj.action_secondary_pressed,
        // inverted block -------------------------------------------------------------
        inverted_background: varsObj.neutral2,
        inverted_headline_color: varsObj.text_main_inverted,
        inverted_content_text: varsObj.text_body1_inverted,
        inverted_content_link: varsObj.action_secondary,
        inverted_content_link_hover: varsObj.action_secondary_hover,
        inverted_content_link_active: varsObj.action_secondary_pressed,
        // middle block ---------------------------------------------------------------
        middle_background: varsObj.neutral6,
        middle_headline_color: varsObj.text_main,
        middle_content_text: varsObj.text_body1,
        middle_content_link: varsObj.action_secondary,
        middle_content_link_hover: varsObj.action_secondary_hover,
        middle_content_link_active: varsObj.action_secondary_pressed,
    };
    // BUTTONS ====================================================================
    const newButtons = {
        // primary --------------------------------------------------------------------
        button_primary_background: varsObj.action_primary,
        button_primary_background_hover: varsObj.action_primary_hover,
        button_primary_background_active: varsObj.action_primary_pressed,
        button_primary_background_disabled: varsObj.action_primary_disabled,
        button_primary_text: varsObj.text_main_inverted,
        button_primary_text_hover: varsObj.text_main_inverted,
        button_primary_text_active: varsObj.text_main_inverted,
        // secondary ------------------------------------------------------------------
        button_secondary_background: varsObj.action_secondary,
        button_secondary_background_hover: varsObj.action_secondary_hover,
        button_secondary_background_active: varsObj.action_secondary_pressed,
        button_secondary_background_disabled: varsObj.action_secondary_disabled,
        button_secondary_text: varsObj.text_main_inverted,
        button_secondary_text_hover: varsObj.text_main_inverted,
        button_secondary_text_active: varsObj.text_main_inverted,
        // outline --------------------------------------------------------------------
        button_outline_background: varsObj.action_neutral_medium,
        button_outline_background_hover: varsObj.action_neutral_hover_medium,
        button_outline_background_active: varsObj.action_neutral_pressed_medium,
        button_outline_background_disabled: varsObj.action_outline_disabled,
        button_outline_text: varsObj.action_neutral_medium,
        button_outline_text_hover: varsObj.action_neutral_medium_hover,
        button_outline_text_active: varsObj.action_neutral_medium_pressed,
    };
    // HEADER =====================================================================
    const newHeader = {
        // mobile ---------------------------------------------------------------------
        header_mobile_main_background: varsObj.header_bg_mobile,
        header_mobile_bottom_background: varsObj.header_bg_mobile,
        header_mobile_separator: varsObj.text_body1_inverted,
        header_mobile_main_icon: varsObj.action_neutral_light,
        header_mobile_bottom_icon: varsObj.text_body1_inverted,
        header_mobile_bottom_text_links: varsObj.text_main_inverted,
        header_mobile_bottom_text_links_background: varsObj.action_secondary,
        // mobile menu ----------------------------------------------------------------
        header_mobile_menu_background: varsObj.neutral2,
        header_mobile_menu_lines: varsObj.action_neutral_light_disabled,
        header_mobile_menu_icons: varsObj.action_neutral_light,
        header_mobile_menu_text_links: varsObj.action_neutral_light,
        header_mobile_menu_text_links_active: varsObj.action_neutral_light_pressed,
        // mobile contacts ------------------------------------------------------------
        header_mobile_contacts_text: varsObj.action_neutral_light,
        // desktop --------------------------------------------------------------------
        header_desktop_main_background: varsObj.header_bg,
        header_desktop_top_background: varsObj.header_bg_top_bar,
        header_desktop_bottom_background: varsObj.header_main_menu_bg,
        header_desktop_separator_background: varsObj.neutral5,
        header_desktop_main_icon: varsObj.action_secondary,
        header_desktop_main_icon_hover: varsObj.action_secondary_hover,
        header_desktop_main_text_link: varsObj.text_main,
        header_main_text_link_hover: varsObj.action_secondary_hover,

        header_desktop_top_icon: varsObj.action_secondary,
        header_desktop_top_icon_hover: varsObj.action_secondary_hover,
        header_desktop_top_text_link: varsObj.text_main,
        header_desktop_top_text_link_hover: varsObj.action_secondary_hover,

        header_desktop_bottom_icon: varsObj.action_neutral_light,
        header_desktop_bottom_icon_hover: varsObj.action_neutral_light_hover,
        header_desktop_bottom_text_link: varsObj.action_neutral_light,
        header_desktop_bottom_text_link_hover: varsObj.action_neutral_light_hover,

        // desktop menu ---------------------------------------------------------------
        header_desktop_menu_text_link: varsObj.action_neutral_light,
        header_desktop_menu_text_link_hover: varsObj.action_neutral_light_hover,
        header_desktop_menu_text_link_active: varsObj.action_neutral_light_pressed,
        header_desktop_submenu_background: varsObj.header_main_menu_dropdown_bg,
        header_desktop_submenu_text_link: varsObj.action_neutral_light,
        header_desktop_submenu_text_link_hover: varsObj.action_neutral_light_hover,
        header_desktop_submenu_text_link_active: varsObj.action_neutral_light_pressed,
        // desktop menu ---------------------------------------------------------------
        header_desktop_menu_info_text_link: varsObj.text_main,
        header_desktop_menu_info_text_link_hover: varsObj.action_primary_hover,
        header_desktop_menu_info_text_link_active: varsObj.action_primary_pressed,
        // search ---------------------------------------------------------------------
        header_desktop_search_background: varsObj.neutral6,
        header_desktop_search_border: varsObj.neutral6,
        header_desktop_search_text: varsObj.text_body1,
        header_desktop_search_icon: varsObj.action_primary,
        header_desktop_search_background_hover: varsObj.neutral6,
        header_desktop_search_border_hover: varsObj.action_primary,
        header_desktop_search_text_hover: varsObj.text_body1,
        header_desktop_search_icon_hover: varsObj.action_primary_hover,
    };
    // FOOTER =====================================================================
    const newFooter = {
        // main --------------------------------------------------------------------
        footer_main_background: varsObj.neutral7,
        footer_bottom_background: varsObj.neutral6,
        footer_lines_background: varsObj.neutral5,
        footer_icon_color: varsObj.neutral4,
        footer_icon_background: varsObj.neutral6,
        footer_text: varsObj.text_body2,
        footer_link: varsObj.action_neutral_dark,
        footer_link_hover: varsObj.action_secondary_hover,
        footer_link_active: varsObj.action_secondary_pressed,
        footer_bottom_text: varsObj.text_body2,
        footer_social_icon: varsObj.action_secondary,
        footer_social_icon_hover: varsObj.action_secondary_hover,
        footer_social_icon_active: varsObj.action_secondary_pressed,
        // menu -----------------------------------------------------------------------
        footer_menu_text_link: varsObj.action_neutral_dark,
        footer_menu_text_link_hover: varsObj.action_secondary_hover,
        footer_menu_text_link_active: varsObj.action_secondary_pressed,
        footer_submenu_text_link: varsObj.action_neutral_dark,
        footer_submenu_text_link_hover: varsObj.action_secondary_hover,
        footer_submenu_text_link_active: varsObj.action_secondary_pressed,
    };
    // FORM =======================================================================
    const newForm = {
        // main -----------------------------------------------------------------------
        form_input_background: varsObj.neutral6,
        form_input_border: varsObj.action_neutral_medium,
        form_input_border_hover: varsObj.action_neutral_medium_hover,
        form_input_text: varsObj.text_body2,
        form_input_text_placeholder: varsObj.neutral7,
        // select ---------------------------------------------------------------------
        form_select_background: varsObj.neutral6,
        form_select_border: varsObj.action_neutral_medium,
        form_select_text: varsObj.text_main,
        form_select_icon: varsObj.action_secondary,
        form_select_option_text: varsObj.text_main,
        form_select_option_text_hover: varsObj.text_body1_inverted,
        form_select_option_background_hover: varsObj.action_neutral_medium_hover,
    };
    // SYSTEM =====================================================================
    const newSystem = {
        success: varsObj.success,
        success_bg: varsObj.success_bg,
        warning: varsObj.warning,
        warning_bg: varsObj.warning_bg,
        error: varsObj.error,
        error_bg: varsObj.error_bg,
    };
    // ACCENT =====================================================================
    const newAccent = {
        accent1: varsObj.accent1,
        accent1_bg: varsObj.accent1_bg,
        accent2: varsObj.accent2,
        accent2_bg: varsObj.accent2_bg,
        accent3: varsObj.accent3,
        accent3_bg: varsObj.accent3_bg,
        accent4: varsObj.accent4,
        accent4_bg: varsObj.accent4_bg,
    };
    // PRODUCT_LIST ===============================================================
    const newProductList = {
        // common ---------------------------------------------------------------------
        product_list_background: varsObj.neutral7,
        product_list_title: varsObj.text_main,
        product_list_description: varsObj.text_body2,
        product_list_price: varsObj.text_main,
        product_list_price_discount: varsObj.text_body2,
        product_list_reviews_start: varsObj.accent2,
        product_list_reviews_text: varsObj.text_body1,
        product_list_availability: varsObj.text_body1,
        // buttons --------------------------------------------------------------------
        product_list_button_buy_background: varsObj.action_primary,
        product_list_button_buy_background_hover: varsObj.action_primary_hover,
        product_list_button_buy_background_active: varsObj.action_primary_pressed,
        product_list_button_buy_background_disabled: varsObj.action_primary_disabled,
        product_list_button_buy_text: varsObj.text_main_inverted,
        product_list_button_buy_text_hover: varsObj.text_main_inverted,
        product_list_button_buy_text_active: varsObj.text_main_inverted,
        // counter --------------------------------------------------------------------
        product_list_counter_background: varsObj.neutral6,
        product_list_counter_color: varsObj.text_main,
        product_list_counter_btn_background: varsObj.neutral7,
        product_list_counter_btn_color: varsObj.action_secondary,
        product_list_counter_btn_background_hover: varsObj.neutral7,
        product_list_counter_btn_color_hover: varsObj.action_secondary_hover,
        product_list_counter_btn_background_active: varsObj.neutral7,
        product_list_counter_btn_color_active: varsObj.action_secondary_pressed,
        // // parameters --------------------------------------------------------------
        product_list_parameters_background: varsObj.neutral7,
        product_list_parameters_icon_color: varsObj.text_main_inverted,
        product_list_parameters_icon_background: varsObj.action_secondary,
        product_list_parameters_text_color: varsObj.text_main,
        product_list_parameters_text_border: varsObj.neutral4,
        // options - buttons ----------------------------------------------------------
        product_list_options_button_background: varsObj.action_secondary_disabled,
        product_list_options_button_border: varsObj.action_secondary_disabled,
        product_list_options_button_text: varsObj.text_main,
        product_list_options_button_background_hover: varsObj.action_secondary_hover,
        product_list_options_button_border_hover: varsObj.action_secondary_hover,
        product_list_options_button_text_hover: varsObj.text_main_inverted,
        product_list_options_button_background_selected: varsObj.action_secondary,
        product_list_options_button_border_selected: varsObj.action_secondary,
        product_list_options_button_text_selected: varsObj.text_main_inverted,
        // options - selects ----------------------------------------------------------
        product_list_options_select_background: varsObj.neutral7,
        product_list_options_select_border: varsObj.action_neutral_medium,
        product_list_options_select_text: varsObj.text_main,
        product_list_options_select_icon: varsObj.action_secondary,
        product_list_options_select_option_text: varsObj.text_main,
        product_list_options_select_option_text_hover: varsObj.text_body1_inverted,
        product_list_options_select_option_background_hover: varsObj.action_neutral_medium_hover,
    };
    // FILTER =====================================================================
    const newFilter = {
        // mobile ---------------------------------------------------------------------
        filter_mobile_background: varsObj.neutral7,
        filter_mobile_border: varsObj.neutral5,
        filter_mobile_text: varsObj.text_body1,
        filter_mobile_icon: varsObj.action_primary,
        // desktop --------------------------------------------------------------------
        filter_desktop_background: varsObj.neutral7,
        filter_desktop_border: varsObj.neutral5,
        filter_desktop_text: varsObj.text_body1,
        filter_desktop_icon: varsObj.action_primary,
        // button ---------------------------------------------------------------------
        filter_button_background: varsObj.action_primary,
        filter_button_background_hover: varsObj.action_primary_hover,
        filter_button_background_active: varsObj.action_primary_pressed,
        filter_button_background_disabled: varsObj.action_primary_disabled,
        filter_button_text: varsObj.text_main_inverted,
        filter_button_text_hover: varsObj.text_main_inverted,
        filter_button_text_active: varsObj.text_main_inverted,
    };
    // PAGING =====================================================================
    const newPaging = {
        paging_background: varsObj.neutral7,
        paging_background_hover: varsObj.action_primary_hover,
        paging_background_active: varsObj.action_primary,
        paging_background_disabled: varsObj.action_primary_disabled,
        paging_text: varsObj.text_main,
        paging_text_hover: varsObj.text_main_inverted,
        paging_text_active: varsObj.text_main_inverted,
        paging_text_disabled: varsObj.text_main,
    };
    // PRODUCT_CARD ===============================================================
    const newProductCard = {
        // common ---------------------------------------------------------------------
        product_card_background: varsObj.neutral7,
        product_card_title: varsObj.text_main,
        product_card_description: varsObj.text_body2,
        product_card_price: varsObj.text_main,
        product_card_price_discount: varsObj.text_body2,
        product_card_reviews_start: varsObj.accent2,
        product_card_reviews_text: varsObj.text_body1,
        product_card_availability: varsObj.text_body1,
        // buttons --------------------------------------------------------------------
        product_card_button_buy_background: varsObj.action_primary,
        product_card_button_buy_background_hover: varsObj.action_primary_hover,
        product_card_button_buy_background_active: varsObj.action_primary_pressed,
        product_card_button_buy_background_disabled: varsObj.action_primary_disabled,
        product_card_button_buy_text: varsObj.text_main_inverted,
        product_card_button_buy_text_hover: varsObj.text_main_inverted,
        product_card_button_buy_text_active: varsObj.text_main_inverted,
        // counter --------------------------------------------------------------------
        product_card_counter_background: varsObj.neutral6,
        product_card_counter_color: varsObj.text_main,
        product_card_counter_btn_background: varsObj.neutral7,
        product_card_counter_btn_color: varsObj.action_secondary,
        product_card_counter_btn_background_hover: varsObj.neutral7,
        product_card_counter_btn_color_hover: varsObj.action_secondary_hover,
        product_card_counter_btn_background_active: varsObj.neutral7,
        product_card_counter_btn_color_active: varsObj.action_secondary_pressed,
        // // parameters --------------------------------------------------------------
        product_card_parameters_background: varsObj.neutral7,
        product_card_parameters_icon_color: varsObj.text_main_inverted,
        product_card_parameters_icon_background: varsObj.action_secondary,
        product_card_parameters_text_color: varsObj.text_main,
        product_card_parameters_text_border: varsObj.neutral4,
        // options - buttons ----------------------------------------------------------
        product_card_options_button_background: varsObj.action_secondary_disabled,
        product_card_options_button_border: varsObj.action_secondary_disabled,
        product_card_options_button_text: varsObj.text_main,
        product_card_options_button_background_hover: varsObj.action_secondary_hover,
        product_card_options_button_border_hover: varsObj.action_secondary_hover,
        product_card_options_button_text_hover: varsObj.text_main_inverted,
        product_card_options_button_background_selected: varsObj.action_secondary,
        product_card_options_button_border_selected: varsObj.action_secondary,
        product_card_options_button_text_selected: varsObj.text_main_inverted,
        // options - selects ----------------------------------------------------------
        product_card_options_select_background: varsObj.neutral7,
        product_card_options_select_border: varsObj.action_neutral_medium,
        product_card_options_select_text: varsObj.text_main,
        product_card_options_select_icon: varsObj.action_secondary,
        product_card_options_select_option_text: varsObj.text_main,
        product_card_options_select_option_text_hover: varsObj.text_body1_inverted,
        product_card_options_select_option_background_hover: varsObj.action_neutral_medium_hover,
    };
    // CART =======================================================================
    const newCart = {
        cart_border: varsObj.neutral5,
    };
    // DEPRICATED =================================================================
    const depricated = {
        neutral1: varsObj.neutral1,
        neutral4: varsObj.neutral4,
        neutral5: varsObj.neutral5,
    };
    return {
        // ...newCommon,
        // ...newFonts,
        ...newGenerals,
        ...newButtons,
        ...newHeader,
        ...newFooter,
        ...newForm,
        ...newSystem,
        ...newAccent,
        ...newProductList,
        ...newFilter,
        ...newPaging,
        ...newProductCard,
        ...newCart,
        ...depricated,
    };
}
