<template>
    <div class="select-template">
        <div class="select-template__field" @click="toggleSelect" ref="select">
            <span class="select-template__arrow material-symbols-outlined" :class="{ 'is-open': isOpen }">
                expand_more
            </span>
            <span v-if="title.color" class="select__item-color" :style="{ backgroundColor: title.color }"></span>
            <span>{{ title.text }}</span>
        </div>
    </div>
</template>

<script>
import EventEmitter from '@/helpers/eventEmitter';
export default {
    name: 'select-template',
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'menus.selectPlaceholder' },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        value: { type: [String, Number, Object, Date, Boolean] },
        disabled: { type: Boolean, default: false },
        width: {
            type: Number,
            default: 340,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    created() {
        EventEmitter.on(`select-template:${this.name}`, this.handleInput);
        EventEmitter.on(`close-templates:${this.name}`, () => (this.isOpen = false));
    },
    beforeDestroy() {
        EventEmitter.off(`select-template:${this.name}`, this.handleInput);
        EventEmitter.off(`close-templates:${this.name}`, () => (this.isOpen = false));
    },
    computed: {
        options() {
            return this.items
                .map((item) => {
                    const obj = {
                        icon: item.icon || false,
                        color: item.color || false,
                        text: item.title || item,
                        value: item.title || item,
                        disabled: item.disabled,
                    };
                    const text = item.title || item;
                    const translation = this.translated ? text : this.$t(text);
                    obj.text = text;
                    obj.translation = translation;
                    return obj;
                })
                .sort((a, b) => {
                    if (typeof a.text === 'number' || typeof b.text === 'number') return 0;
                    else return a.text.localeCompare(b.text);
                });
        },

        title() {
            const selectedItem = this.options.find((option) => this.value === option.value);
            if (selectedItem?.value)
                return {
                    type: '-value',
                    text: this.$t(selectedItem.text),
                    icon: selectedItem.icon || false,
                    color: selectedItem.color || false,
                };
            if (this.placeholder) return { type: '-placeholder', text: this.$t(this.placeholder) };
            return { type: '-placeholder', text: this.name };
        },
    },
    methods: {
        handleInput(item) {
            this.$emit('input', item.title);
            this.toggleSelect();
        },
        toggleSelect() {
            EventEmitter.trigger('toggle-templates', !this.isOpen, this.items, this.name, this.width, this.value);
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select-template {
    &__field {
        display: flex;
        position: relative;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding-left: 16px;
        height: $form-control-height;
        padding: 0 2 * $form-control-padding-horizontal 0 $form-control-padding-horizontal;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;
        line-height: calc(#{$form-control-height} - 2px);
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &:focus {
            border-color: $form-control-border-color-hover;
        }

        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }

        .material-icons,
        .material-icons-outlined {
            margin-right: 6px;
        }
    }

    &__arrow {
        translate: $transition-fast;
        pointer-events: none;
        transform: rotate(90deg);
        margin-right: 4px;
        margin-left: -6px;
        &.is-open {
            transform: rotate(270deg);
        }
    }
}
</style>
