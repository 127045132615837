<template>
    <div class="template-builder__input-group">
        <form-item
            v-if="helperItem.component === 'Select'"
            class="template-builder__form-item option-list-settings__item-field"
        >
            <Select
                can-be-empty
                @input="changeComponent"
                v-model="modelValue.component"
                :items="itemsForSelect"
            ></Select>
        </form-item>
        <!--
    :label-trans="item.name === 'SecondSearch' ? null : item.name"
-->
        <form-item
            v-if="helperItem.customization.component"
            class="template-builder__form-item option-list-settings__item-field"
        >
            <component
                :is="helperItem.customization.component"
                v-bind="helperItem.customization.props"
                @input="handlePropsInput"
                :default-props="helperItem.templateComponent === 'Column' ? defaultProps : null"
            ></component>
        </form-item>
        <div v-if="hasSeparator" class="template-builder-input-group__separator"></div>
    </div>
</template>

<script>
import Select from '@/components/form/controls/Select';
import CodeEditor from '@/components/form/controls/CodeEditor';
import ButtonBuilder from '@/components/form/controls/ButtonBuilder';
import InputNumberAndRange from '@/components/form/controls/InputNumberAndRange';
import FormItem from '@/components/form/item';
import InputText from '@/components/form/controls/InputText';
import { cloneDeep, isEqual, debounce } from 'lodash';

export default {
    name: 'template-builder-input-group',
    components: {
        Select,
        InputNumberAndRange,
        FormItem,
        InputText,
        CodeEditor,
        ButtonBuilder,
        TemplateBuilderInputColumn: () =>
            import('@/components/builder/optionList/template-builder/template-builder-input-column'),
    },
    props: {
        value: { type: Object, default: null },
        defaultProps: { type: Object, required: true },
        customItemsForSelect: { type: Array, default: null },
        hasSeparator: { type: Boolean, default: true },
        type: { type: String, default: '' },
    },

    data() {
        return {
            modelValue: null,
            helperItem: null,
            counter: 0,
            itemsForSelect: this.customItemsForSelect || this.defaultProps.getItemsForComponentSelect(this.type),
        };
    },

    methods: {
        handleValue() {
            const modelValue = cloneDeep(this.value);
            let helperItem = cloneDeep(this.defaultProps.getItemByTemplateComponent(this.value.component));
            if (!helperItem) {
                helperItem = { component: 'Select', customization: {} };
            }
            const customization = helperItem.customization;
            if (customization.component) {
                if (customization.component === 'Select') {
                    customization.props.items = Object.keys(customization.variants).map((item) => {
                        return { text: `settings.header.templateItems.variants.${item}`, value: item };
                    });
                    let selected = this.getSelectValue(modelValue.props, customization.variants);
                    if (!selected) {
                        modelValue.props = customization.default;
                        this.$emit('input', modelValue);
                    } else {
                        customization.props.value = selected;
                    }
                } else {
                    const valueName = customization.valueName;
                    customization.props.value = valueName ? modelValue.props[valueName] : modelValue.props;
                }
            }
            this.modelValue = modelValue;
            this.helperItem = helperItem;
        },
        getSelectValue(props, items) {
            for (const key in items) {
                if (isEqual(items[key], props)) {
                    return key;
                }
            }
        },
        changeComponent(val) {
            if (val) {
                const newItem = this.defaultProps.getItemByTemplateComponent(val);
                this.$emit('input', {
                    component: newItem.templateComponent,
                    props: cloneDeep(newItem.customization.default),
                });
            } else {
                this.$emit('input', { component: null });
            }
        },
        handlePropsInput: debounce(function(val) {
            const newValue = cloneDeep(this.modelValue);
            const customization = this.helperItem.customization;
            if (customization.valueName) {
                newValue.props[customization.valueName] = val;
            } else {
                newValue.props = customization.variants[val];
            }
            this.$emit('input', newValue);
        }, 300),
    },
    watch: {
        value: {
            handler(newVal, oldVal) {
                if (!isEqual(newVal, oldVal)) {
                    this.handleValue();
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.template-builder-input-group {
    &__separator {
        border-bottom: 1px solid var(--v-outline-base);
        width: 100%;
        margin-top: 16px;
    }
}
</style>
